section{
    .contacto{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 10px 20px 50px 20px;
        h2{
            width: 100%;
            font-size: 26px;
            text-align: center;
        }
        .container-form{
            width: 550px;
            background-color: $colorGray;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 40px;
            border-radius: 20px;
            form{
                width: 100%;
                margin-top: 20px;
                input, textarea{
                    width: 100%;
                    padding: 10px;
                    border: none;
                    margin: 5px 0px;
                    font-size: 16px;
                    color: $colorTxt;
                }
                textarea{
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 100px;
                    max-height: 400px;
                }
                .btn{
                    background-color: $colorSecondary;
                    color: $colorWhite;
                    width: 100px;
                }
                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }
            }
        }
        .container-txt{
            width: 550px;
            display: flex;
            flex-direction: column;
            article{
                max-width: 100%;
                color: $colorWhite;
                padding: 20px 40px;
                font-size: 26px;
                line-height: 31px;
                font-weight: 100;
                span{
                    color: $colorSecondary;
                    font-weight: 900;
                    font-size: 30px;
                    line-height: 0px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .contacto{
            .container-form{
                form{
                    width: 500px;
                }
            }
        }
    }
}