@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap');

$colorMain: #22549D;
$colorSecondary: #68A7DD;
$colorWhite: #fff;
$colorGray: #efefef;
$colorTxt: #333333;

*{
	padding: 0;
	margin: 0;
	font-family: 'Archivo', sans-serif;
	font-weight: 300;
	color: $colorMain;
	text-decoration: none;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body{
	width: 100%;
	background-color: $colorWhite;
}

.appContainer{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main{
	flex-grow: 1;
}

section{
	width: 100%;
	max-width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.home{
	background: linear-gradient(90deg, rgba(57,85,151,1) 0%, rgba(40,44,80,1) 100%);
}

.top-bar{
	width: 100%;
	height: 16px;
	background: linear-gradient(90deg, rgba(40,44,80,1) 0%, rgba(57,85,151,1) 100%);
}

.head-logo{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 50px 20px;
	h1{
		color: $colorSecondary;
		font-size: 30px;
		margin-top: 40px;
		padding: 10px;
	}
}

.container-productos{
	width: 100%;
	height: auto;
	background-color: $colorGray;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px 10px;
	h3{
		width: 95%;
		background: linear-gradient(90deg, rgba(57,85,151,1) 0%, rgba(40,44,80,1) 100%);
		color: $colorWhite;
		padding: 10px 15px;
		border-radius: 15px;
		font-size: 20px;
		font-weight: 500;
		text-align: center;
	}
	.productos-detalle{
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		padding: 50px 10px 10px 10px;
		.detalle3{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-wrap: wrap;
			.detalle{
				width: 225px;
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin: 20px 30px;
				article{
					text-align: center;
					font-size: 16px;
					margin: 15px;
					color: $colorTxt;
				}
				img{
					width: 225px;
				}
			}
		}
		
	}
}

.container-caracteristicas{
	width: 100%;
	height: auto;
	background-color: $colorMain;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px 10px;
	h3{
		width: 95%;
		background-color: $colorWhite;
		color: $colorMain;
		padding: 10px 15px;
		border-radius: 15px;
		font-size: 20px;
		font-weight: 500;
		text-align: center;
	}
	.caracteristicas-detalle{
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		padding: 50px 10px 10px 10px;
		.detalle{
			width: 100px;
			height: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 20px 50px;
			article{
				text-align: center;
				font-size: 16px;
				margin: 15px;
				color: $colorWhite;
			}
		}
	}
}

.container-industrias{
	width: 100%;
	height: auto;
	background-color: $colorWhite;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px 10px;
	h3{
		width: 95%;
		background: linear-gradient(90deg, rgba(57,85,151,1) 0%, rgba(40,44,80,1) 100%);
		color: $colorWhite;
		padding: 10px 15px;
		border-radius: 15px;
		font-size: 20px;
		font-weight: 500;
		text-align: center;
	}
	.industrias-detalle{
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		padding: 50px 10px 10px 10px;
		.detalle{
			width: 100px;
			height: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 20px 50px;
			article{
				text-align: center;
				font-size: 16px;
				margin: 15px;
				color: $colorTxt;
			}
		}
	}
}

@import 'components/ContactoForm/contacto-form';

@import 'components/Footer/footer';


@media only screen and (min-width: 1000px){
    .container-productos{
		h3{
			width: 480px;
		}
	}
	.container-caracteristicas{
		h3{
			width: 380px;
		}
	}
	.container-industrias{
		h3{
			width: 380px;
		}
	}
}

