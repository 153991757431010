@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Archivo", sans-serif;
  font-weight: 300;
  color: #22549D;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  background-color: #fff;
}

.appContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

section {
  width: 100%;
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.home {
  background: linear-gradient(90deg, rgb(57, 85, 151) 0%, rgb(40, 44, 80) 100%);
}

.top-bar {
  width: 100%;
  height: 16px;
  background: linear-gradient(90deg, rgb(40, 44, 80) 0%, rgb(57, 85, 151) 100%);
}

.head-logo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
}
.head-logo h1 {
  color: #68A7DD;
  font-size: 30px;
  margin-top: 40px;
  padding: 10px;
}

.container-productos {
  width: 100%;
  height: auto;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
}
.container-productos h3 {
  width: 95%;
  background: linear-gradient(90deg, rgb(57, 85, 151) 0%, rgb(40, 44, 80) 100%);
  color: #fff;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.container-productos .productos-detalle {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 10px 10px 10px;
}
.container-productos .productos-detalle .detalle3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.container-productos .productos-detalle .detalle3 .detalle {
  width: 225px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 30px;
}
.container-productos .productos-detalle .detalle3 .detalle article {
  text-align: center;
  font-size: 16px;
  margin: 15px;
  color: #333333;
}
.container-productos .productos-detalle .detalle3 .detalle img {
  width: 225px;
}

.container-caracteristicas {
  width: 100%;
  height: auto;
  background-color: #22549D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
}
.container-caracteristicas h3 {
  width: 95%;
  background-color: #fff;
  color: #22549D;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.container-caracteristicas .caracteristicas-detalle {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 10px 10px 10px;
}
.container-caracteristicas .caracteristicas-detalle .detalle {
  width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 50px;
}
.container-caracteristicas .caracteristicas-detalle .detalle article {
  text-align: center;
  font-size: 16px;
  margin: 15px;
  color: #fff;
}

.container-industrias {
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
}
.container-industrias h3 {
  width: 95%;
  background: linear-gradient(90deg, rgb(57, 85, 151) 0%, rgb(40, 44, 80) 100%);
  color: #fff;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.container-industrias .industrias-detalle {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 10px 10px 10px;
}
.container-industrias .industrias-detalle .detalle {
  width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 50px;
}
.container-industrias .industrias-detalle .detalle article {
  text-align: center;
  font-size: 16px;
  margin: 15px;
  color: #333333;
}

section .contacto {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 50px 20px;
}
section .contacto h2 {
  width: 100%;
  font-size: 26px;
  text-align: center;
}
section .contacto .container-form {
  width: 550px;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 20px;
}
section .contacto .container-form form {
  width: 100%;
  margin-top: 20px;
  /* Chrome, Safari, Edge, Opera */
}
section .contacto .container-form form input, section .contacto .container-form form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  margin: 5px 0px;
  font-size: 16px;
  color: #333333;
}
section .contacto .container-form form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
}
section .contacto .container-form form .btn {
  background-color: #68A7DD;
  color: #fff;
  width: 100px;
}
section .contacto .container-form form input::-webkit-outer-spin-button,
section .contacto .container-form form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
section .contacto .container-txt {
  width: 550px;
  display: flex;
  flex-direction: column;
}
section .contacto .container-txt article {
  max-width: 100%;
  color: #fff;
  padding: 20px 40px;
  font-size: 26px;
  line-height: 31px;
  font-weight: 100;
}
section .contacto .container-txt article span {
  color: #68A7DD;
  font-weight: 900;
  font-size: 30px;
  line-height: 0px;
}

@media only screen and (min-width: 1000px) {
  section .contacto .container-form form {
    width: 500px;
  }
}
footer {
  width: 100%;
  height: auto;
  background-color: #22549D;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 30px;
  color: #fff;
}

@media only screen and (min-width: 1000px) {
  .container-productos h3 {
    width: 480px;
  }
  .container-caracteristicas h3 {
    width: 380px;
  }
  .container-industrias h3 {
    width: 380px;
  }
}/*# sourceMappingURL=styles.css.map */