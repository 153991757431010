footer{
	width: 100%;
	height: auto;
	background-color: $colorMain;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	padding: 30px;
	color: $colorWhite;
}